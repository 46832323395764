import React from 'react';
import './footer.css';
import { Row, Col, Container } from 'reactstrap';

// import { library } from '@fortawesome/fontawesome-svg-core'
import {
  // faWifi,
  faStreetView,
  faDoorOpen,
  faGift,
} from '@fortawesome/free-solid-svg-icons';
// library.add(fab, faCheckSquare, faCoffee)
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ marginTop: '100px' }}>
        <Container fluid={false} style={{ marginTop: '2.5rem' }}>
          <Row>
            <Col sm={{ size: 4 }}>
              {/* <div itemScope itemType="http://schema.org/Hotel">
                Check-in: <meta itemProp="checkinTime" content="12:00:00" />12:30 PM
              Check-out: <meta itemProp="checkoutTime" content="11:00:00" />11:00 AM
              </div><br /> */}
              <h3 style={{ fontFamily: 'AllerLight' }}>Contact Us</h3>
              <p>SV Road, Near Suburbia Movie time </p>
              <p>Bandra West,Mumbai 400050</p>
              <p>Email Us: HotelGraceGalaxy@Gmail.com</p>
              <p>Phone: +91 77188 00522</p>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
