import React from 'react';
import './zetaSection.css';
import {
  // Row,
  // Col,
  Container,
  Button,
} from 'reactstrap';
class ZetaSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Container
          fluid={true}
          style={{
            backgroundColor: '#1c1c1c',
            padding: '40px',
            marginTop: '4rem',
            marginBottom: '4rem',
          }}
        >
          <h1
            style={{
              color: '#fff',
              fontFamily: 'Aller',
              marginBottom: '2rem',
              textAlign: 'center',
            }}
          >
            Zeta
          </h1>
          <h2
            style={{
              color: '#fff',
              fontFamily: 'Aller',
              marginBottom: '4rem',
              textAlign: 'center',
            }}
          >
            Banqueting and conference hall
          </h2>
          <div style={{ margin: '0 auto', width: '145px' }}>
            <Button id="zetaSectionButton"><a href="/zeta-party-banquet-conference-hall-bandra-west-mumbai">KNOW MORE</a></Button>
          </div>
        </Container>
      </div>
    );
  }
}
export default ZetaSection;
