import React from 'react';
import './iconSection.css';
import { Row, Col, Container } from 'reactstrap';
// import { library } from '@fortawesome/fontawesome-svg-core'
import {
  // faWifi,
  faStreetView,
  faDoorOpen,
  faGift,
} from '@fortawesome/free-solid-svg-icons';
// library.add(fab, faCheckSquare, faCoffee)
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class IconSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Container style={{ marginTop: '2.5rem' }}>
          <h1 style={{ textAlign: 'center', fontFamily: 'Aller' }}>
            {this.props.title}
          </h1>
        </Container>
        <Container style={{ marginTop: '2.5rem' }}>
          <Row>
            <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 12 }}>
              <div className="iconContainer">
                <FontAwesomeIcon
                  icon={faDoorOpen}
                  style={{ fontSize: '84px', color: '#EF8921' }}
                />
              </div>

              <div
                style={{
                  fontWeight: 'bolder',
                  textAlign: 'center',
                  margin: '0 auto',
                }}
              >
                Rooms for all budget
              </div>
            </Col>
            <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 12 }}>
              <div className="iconContainer">
                <FontAwesomeIcon
                  icon={faStreetView}
                  style={{ fontSize: '84px', color: '#EF8921' }}
                />
              </div>
              <div
                style={{
                  fontWeight: 'bolder',
                  textAlign: 'center',
                  margin: '0 auto',
                }}
              >
                Most accessible location in the city
              </div>
            </Col>
            <Col lg={{ size: 4 }} md={{ size: 4 }} sm={{ size: 12 }}>
              <div className="iconContainer">
                <FontAwesomeIcon
                  icon={faGift}
                  style={{ fontSize: '84px', color: '#EF8921' }}
                />
              </div>

              <div
                style={{
                  fontWeight: 'bolder',
                  textAlign: 'center',
                  margin: '0 auto',
                }}
              >
                Ask for corporate offers
              </div>
            </Col>
            {/* <Col lg={{size:4}} sm={{size:12}}>
            <div className="iconContainer">
            <FontAwesomeIcon icon={faWifi} style={{fontSize:'84px',color:'#EF8921',}} />
            </div>
                <div style={{fontWeight:'bolder',textAlign:'center', margin:'0 auto'}}>Free Wifi</div>
            </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}
export default IconSection;
