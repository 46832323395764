import React from 'react';
import './headerJumbotron.css';
import { Jumbotron, Container } from 'reactstrap';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql } from 'gatsby';

class HeaderJumbotron extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerText: props.headerText,
      jumboImage: props.jumboImage
    };
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            rgsl: file(
              relativePath: { eq: "images/gallery/rajiv-gandhi-sea-link.jpg" }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            royalRoom: file(
              relativePath: {
                eq: "images/gallery/royal-hotel-room-bandra-mumbai.jpg"
              }
            ) 
            {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <BackgroundImage
            className={'s'}
            fluid={this.props.jumboImage.childImageSharp.fluid}
            style={{ zIndex: '-1' }}
          >
            <Jumbotron fluid style={{ background: 'none', maxHeight: '30rem' }}>
              <Container style={{ minHeight: '30rem' }}>
                {/* <h1 id="jumboText">{this.state.headerText}</h1> */}
                {/* <p style={{color:"white", fontFamily:"Aller"}}>Experience Bandra with us</p> */}
              </Container>
            </Jumbotron>
          </BackgroundImage>
        )}
      />
    );
  }
}
export default HeaderJumbotron;
