import React from 'react';
import './roomsBox.css';
import { StaticQuery, graphql } from 'gatsby';
// import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

import { Row, Col, Container, Button } from 'reactstrap';
class RoomsBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      description: props.description,
      buttonText: props.buttonText,
      buttonLink: props.buttonLink,
      image: props.image,
    };
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            royal: file(
              relativePath: {
                eq: "images/gallery/royal-hotel-room-bandra-mumbai.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            superior: file(
              relativePath: {
                eq: "images/gallery/premium-hotel-room-bandra-mumbai.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            budget: file(
              relativePath: {
                eq: "images/gallery/budget-hotel-room-bandra-mumbai.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            deluxe: file(
              relativePath: {
                eq: "images/gallery/deluxe-hotel-room-bandra-mumbai.jpg"
              }
            ) {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <Container
              style={{
                backgroundColor: 'rgb(244,242,239)',
                padding: '20px',
                marginTop: '6rem',
                marginBottom: '4rem',
              }}
            >
              <Row style={{ marginTop: '20px' }}>
                <Col xs={{ size: 12 }} lg={{ size: 6 }} md={{ size: 6 }}>
                  <div style={{ padding: '20px' }}>
                    <h1 style={{ fontFamily: 'AllerLight' }}>Royale.</h1>
                    <h3 style={{ fontSize: '20px', fontFamily: 'AllerLight' }}>
                      Extraordinary rooms with everything luxury
                    </h3>
                    <div
                      style={{
                        verticalAlign: '',
                        width: '145px',
                        fontFamily: 'AllerLight',
                      }}
                    >
                      <a href="/book">Book Now</a>
                    </div>
                  </div>
                </Col>
                <Col xs={{ size: 12 }} lg={{ size: 6 }} md={{ size: 6 }}>
                  <div>
                    <Img
                      style={{ borderRadius: '4px' }}
                      fluid={data.royal.childImageSharp.fluid}
                    ></Img>
                  </div>
                </Col>
              </Row>
            </Container>

            <Container
              style={{
                padding: '20px',
                marginTop: '4rem',
                marginBottom: '4rem',
              }}
            >
              <Row style={{ marginTop: '20px' }}>
                <Col>
                  <Img
                    style={{ borderRadius: '4px' }}
                    fluid={data.deluxe.childImageSharp.fluid}
                  ></Img>
                  <div style={{ paddingTop: '20px' }}>
                    <h2 style={{ fontFamily: 'AllerLight' }}>Superior.</h2>
                    <h3 style={{ fontSize: '20px', fontFamily: 'AllerLight' }}>
                      Best suited for business travellers and corporates
                    </h3>
                    <div style={{ width: '145px', fontFamily: 'AllerLight' }}>
                      <a href="/book">Book Now</a>
                    </div>
                  </div>
                </Col>
                <Col>
                  <Img
                    style={{ borderRadius: '4px' }}
                    fluid={data.budget.childImageSharp.fluid}
                  ></Img>
                  <div style={{ paddingTop: '20px' }}>
                    <h2 style={{ fontFamily: 'AllerLight' }}>Compact.</h2>
                    <h3 style={{ fontSize: '20px', fontFamily: 'AllerLight' }}>
                      Economy rooms for pocket friendly stay in the city
                    </h3>
                    <div style={{ width: '145px', fontFamily: 'AllerLight' }}>
                      <a href="/book">Book Now</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

            {/* <Container
          style={{
            backgroundColor:"rgb(244,242,239)",
            padding: '20px',
            marginTop: '4rem',
            marginBottom: '4rem',
          }}
        >
          <Row style={{marginTop:"20px"}}>
            <Col>
              <div style={{padding:'20px'}}>
                <h2 style={{fontFamily:'AllerLight'}}>Superior.</h2>
                <h3 style={{fontSize:"20px",fontFamily:'AllerLight'}}> 
                  Luxury amenities, round the clock service for all your needs. Tailor made for a classy vacation in Mumbai
                </h3>
                <div style={{ marginTop: ' 45%', width: '145px',fontFamily:"AllerLight" }}>
                Check out all rooms 
              </div>
              </div>
            </Col>
            <Col><div><Img style={{ borderRadius:"4px"}} fluid={data.superior.childImageSharp.fluid} ></Img></div></Col>
          </Row>
        </Container> */}
          </div>
        )}
      />
    );
  }
}
export default RoomsBox;
